import { lazy, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { zhTW } from '@mui/material/locale';


const theme = createTheme(
  zhTW,
);

const CCOMPONENTS = {
  NavBar: lazy(() => import(/* webpackChunkName: "navbar" */ "components/_NavBar")),
  Profile: lazy(() => import(/* webpackChunkName: "profile" */ "pages/Profile")),
}


const navbar = ReactDOM.createRoot(
  document.getElementById('navbar') as HTMLElement
)


navbar.render(
  <>
    <StrictMode>
      <ThemeProvider theme={theme}>
        <CCOMPONENTS.NavBar />
      </ThemeProvider>
    </StrictMode>
  </>
)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            <Route index />
            <Route path="/Profile" element={<CCOMPONENTS.Profile />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </StrictMode>
);





reportWebVitals();
